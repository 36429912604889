<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.947998V20.948H20V0.947998H0ZM8.5 16.048L2.90002 10.448L5 8.34799L8.4 11.748L14.8 5.34799L16.9 7.448L8.5 16.048Z"
      class="check-icon"
    />
  </svg>
</template>
<script>
export default {
  name: "CheckIcon",
  props: {
    color: {
      type: String,
      default: () => "",
    },
  },
};
</script>
<style lang="scss">
path.check-icon {
  fill: var(--primary-color);
}
</style>